jQuery(document).ready(function ($) {

    AOS.init({
        'once': true
    });
        
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col-bread').matchHeight({
        target: jQuery('.col-svg'),
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: jQuery('#site-logo'),
        remove: false
    });

    // Function to animate the side menu
    function animateMenu(element, action) {
        const $menuWrapper = jQuery("#side_menu_wrapper");
        const $hamburgerIcons = jQuery("#hamburger-icon, #hamburger-icon-sticky, #hamburger-icon-close");

        function hideMenu() {
            TweenLite.to($menuWrapper, 0.5, { ease: Power4.easeInOut, x: '0%' });
            $hamburgerIcons.addClass("closed").removeClass("is-active");
            jQuery("body").removeClass("menu-open");
            $menuWrapper.removeClass('open');
            $menuWrapper.removeClass('sticky'); // Remove 'sticky' class when closing the menu
        }

        function showMenu() {
            TweenLite.to($menuWrapper, 0.5, { ease: Power4.easeInOut, x: '-100%' });
            $hamburgerIcons.removeClass("closed").addClass("is-active");
            jQuery("body").addClass("menu-open");
            $menuWrapper.addClass('open');
            if (element.attr('id') === 'hamburger-icon-sticky') {
                $menuWrapper.addClass('sticky'); // Add 'sticky' class when showing the menu from sticky header
            }
        }

        if (action === 'close') {
            hideMenu();
        } else if ($menuWrapper.hasClass('open')) {
            hideMenu();
        } else {
            showMenu();
        }
    }


    jQuery("#hamburger-icon").on('click', function() {
        animateMenu(jQuery(this), '');
    });

    jQuery("#hamburger-icon-sticky").on('click', function() {
        animateMenu(jQuery(this), '');
    });

    jQuery(".main").on('click', function() {
        animateMenu(jQuery(this), 'close');
    });

    jQuery("#side_menu_wrapper .btn--header").on('click', function() {
        animateMenu(jQuery(this), 'close');
    });

    jQuery('#mobile-menu a').on('click', function() {
        animateMenu(jQuery(this), 'close');
    });

    // mobile menu scrolling
    jQuery('#scrollable-wrapper').slimScroll({
        height: '100vh',
        alwaysVisible: false
    });

    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 200) {
            jQuery('#sticky-header').addClass('visible');
        } else {
            jQuery('#sticky-header').removeClass('visible');
        }
    });
    $('#side_menu_wrapper').on('mouseenter', function () {
        $('body').css('overflow', 'hidden');
    });

    $('#side_menu_wrapper').on('mouseleave', function () {
        $('body').css('overflow', 'auto');
    });
});
